import { MenuItem, Divider } from 'react-md';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { useEffect, useState } from 'react';
import api from '@utilities/claApi';
import useAdaptive from "@utilities/hooks/useAdaptive";
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';

export const DropdownMenuItem = ({ isEntry, taxPayerName, priorYearReturnId, taxpayerSpouseName, isLastItem, organizer, avatarWidth, avatarHeight, avatarFontSize, dropdownFontSize }) => {
  const { ACTION, dispatch, selectState, REDUX, history } = usePageFramework();
  const currentTaxPayer = selectState(REDUX.TAXPAYER_NAME);
  const { isMobile } = useAdaptive();

  const [initials, setInitials] = useState('');

  const { id, client, status, year, isTermsAgreed, entryExperience } = organizer;

  const setSelectedClientDetails = async () => {
    const result = await api.get(`/organizers/${id}/dashboard`);
    const dashboard = result?.data?.dashboard;
    
    dispatch(ACTION.setDashboard(dashboard || defaultDashboard));
    dispatch(ACTION.setDashboardId(result?.data?.id));

    if (!taxPayerName) throw new Error('Taxpayer data not found');
    dispatch(ACTION.setTaxpayerName(taxPayerName));
    dispatch(ACTION.setpriorYearReturnId(priorYearReturnId));
    dispatch(ACTION.setTaxPayerSpouseName(taxpayerSpouseName));
    dispatch(ACTION.setOrganizerId(id));
    dispatch(ACTION.setSelectedOrganizer(organizer));
    dispatch(ACTION.setActiveReturn({
      displayName: client.name,
      clientNumber: client?.number ?? '',
      formStatus: status,
      currentYear: year
    }));

  };

  const setTaxpayerDetails = async (taxPayerName) => {
    if (!isEntry) {
      // return if same clientvis selected
      if (taxPayerName === currentTaxPayer) return;
      // check if agreement has been signed

      if (!entryExperience?.completedStep) {
        // Show the progress modal immediately
        dispatch(ACTION.setProgressText(`Loading Client Data for\n${taxPayerName}${taxpayerSpouseName ? ` \nand ${taxpayerSpouseName}` : ''}`));
        dispatch(ACTION.setProgressVisible(true));

        // Set a 2-second timeout before showing the entry modal
        setTimeout(async () => {
          // Hide the progress modal
          dispatch(ACTION.setProgressVisible(false));

          // Open the entry modal and proceed with other actions after 2 seconds
          dispatch(ACTION.setEntryModalOpen(true));
          dispatch(ACTION.setCompletedEntryStep(0));
          await setSelectedClientDetails();

          if (isTermsAgreed) {
            history.push({
              pathname: '/',
              state: { gettingStarted: true }
            });
          } else {
            history.push({
              pathname: '/',
              state: { agreementAcceptance: true }
            });
          }
        }, 3000); // Delay for 2 seconds
      } else {
        dispatch(ACTION.setProgressText(`Loading Client Data for\n${taxPayerName}${taxpayerSpouseName ? ` \nand ${taxpayerSpouseName}` : ''}`));
        dispatch(ACTION.setProgressVisible(true));

        setTimeout(async () => {
          // Hide the progress modal
          dispatch(ACTION.setProgressVisible(false));
          await setSelectedClientDetails();
        }, 3000); // Delay for 2 seconds
      }
    }
    // get the organizer dashboard
    else {
      try {
        await setSelectedClientDetails();
      } catch (error) {
        console.error('Error getting the organizer data', error.message);
        dispatch(ACTION.setShowCustomDialog(true));
        dispatch(ACTION.setCustomDialogTitle(error.message || 'Error'));
      } finally {
        dispatch(ACTION.setProgressVisible(false));
      };
    }
  };

  const menuItemStyle = {
    width: isEntry ? isMobile ? 'auto' : '394px' : '332px',
    maxWidth: isMobile ? '394px' : 'auto',
    paddingLeft: isEntry ? '23px' : '11px',
    paddingRight: isEntry ? '23px' : '15px',
    paddingTop: '10px',
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
    margin: '0px',
    paddingBottom: '4px',
  };

  const wrapperStyle = {
    width: '100%',
    overflow: 'hidden',
    fontFamily: 'museo-sans',
  };

  const avatarStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: avatarWidth || '45px',
    height: avatarHeight || '45px',
    fontSize: '21px',
    fontWeight: 400,
    color: '#2E334E',
    padding: '6px',
    backgroundColor: '#2E334E',
    borderRadius: '50%',
    marginRight: '16px',
  };

  // Avatar text style
  const avatarTextStyle = {
    color: '#FFFFFF',
    fontSize: avatarFontSize || '21px',
    fontWeight: 400,
  };

  // Style for the content section
  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    marginTop: '2px',
    lineHeight: '26px',
  };

  // Style for the tax payer name
  const nameStyle = {
    color: '#2E334E',
    fontSize: dropdownFontSize || '18px',
    fontWeight: 600,
    margin: '0px',
    lineHeight: isEntry ? '26px' : '16.8px',
    fontFamily: 'museo-sans',
  };

  // Style for the details (Return ID and Spouse)
  const detailStyle = {
    margin: '0px',
    color: '#4B4B4B',
    lineHeight: isEntry ? '20px' : '14.4px',
    fontSize: isEntry ? '14px' : '12px',
  };

  const detailLabelStyle = {
    fontWeight: 600,
    margin: '0px',
  };

  const detailValueStyle = {
    fontWeight: 400,
    margin: '0px',
    fontSize: isEntry ? '14px' : '12px',
  };

  const getInitials = (taxPayerName) => {
    const initials = taxPayerName.split(' ').slice(0, 2).map(part => part[0].toUpperCase()).join('');
    setInitials(initials);
    return initials;
  };

  useEffect(() => {
    if (taxPayerName) getInitials(taxPayerName);
  }, []);

  return (
    <>
      <MenuItem key={priorYearReturnId} onClick={() => setTaxpayerDetails(taxPayerName)} style={menuItemStyle}>
        <div style={wrapperStyle}>
          <div style={containerStyle}>
            {/* Avatar section */}
            <div style={avatarStyle}>
              <p style={avatarTextStyle}>{initials}</p>

            </div>

            {/* Content section */}
            <div style={contentStyle}>
              <p style={nameStyle}>{taxPayerName}</p>

              {/* Return ID section */}
              <div style={detailStyle}>
                <span style={detailLabelStyle}>Return ID:</span>
                <span style={detailValueStyle}>{' '}{priorYearReturnId}</span>
              </div>

              {/* Conditionally render the Spouse name if available */}
              {taxpayerSpouseName && (
                <div style={{ ...detailStyle, lineHeight: isEntry ? '16.8px' : '14.4px' }}>
                  <span style={detailLabelStyle}>Spouse:</span>
                  <span style={detailValueStyle}>{' '}{taxpayerSpouseName}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </MenuItem>
      {!isLastItem && <Divider style={{ backgroundColor: '#d8d8d8', height: '0.5px', opacity: 0.5, width: '85%' }} />}
    </>
  );
};
