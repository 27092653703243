import React, { useEffect } from 'react';
import { Button, Divider } from 'react-md';

import { MenuSVGIcon, CloseSVGIcon } from '@react-md/material-icons';
import { TextIconSpacing } from '@react-md/icon';
import { useMsal } from '@azure/msal-react';
import { useIntercom } from 'react-use-intercom';

import './headerMenuMobile.scss';
import { injectLocalDevelopmentTokens } from '@utilities/authentication.js';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { scopes } from '@utilities/claApi.js';
import * as IMG from '@utilities/constants/images';
import * as STRING from '@utilities/constants/strings';

function HeaderMenu() {
  const {
    history,
    dispatch,
    selectState,
    REDUX,
    NAV,
    location,
    ACTION,
    validateCurrentCard,
  } = usePageFramework();
  const [visible, setVisible] = React.useState(false);
  const { instance } = useMsal();
  const { shutdown } = useIntercom();
  const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
  const isAdmin = selectState(REDUX.IS_ADMIN);
  const isAuthenticated = selectState(REDUX.IS_AUTHENTICATED);
  const activeReturn = selectState(REDUX.ACTIVE_RETURN);
  const toolbarTitle = selectState(REDUX.TOOLBAR_TITLE);
  const isPracDash =
    toolbarTitle === STRING.EXCHANGE_MANAGER_1040 ||
    toolbarTitle === 'Document Manager';
  const isDashboard =
    toolbarTitle === STRING.DASHBOARD || location?.pathname === '/dashboard';
  const active = 'headerMenuTextSelectedMobile menuTextMobile';
  const inactive = 'headerMenuTextMobile menuTextMobile';
  const goBack = () => {
    setVisible(false);
    validateCurrentCard();
    dispatch(ACTION.setCurrentCardKey(STRING.DASHBOARD_KEY));
    dispatch(ACTION.setDashboardFilterStatus(-1));
    dispatch(ACTION.setDashboardSortRequirements('traditional'));
    history.push(NAV.DASHBOARD);
  };

  useEffect(() => {
    visible
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll');
  }, [visible]);

  const handleSignin = (e) => {
    setVisible(false);
    if (
      !process.env.NODE_ENV ||
      (process.env.NODE_ENV === 'development' &&
        !process.env.REACT_APP_DANGER_BYPASS_INJECT_LOCAL_TOKENS)
    ) {
      // inject development tokens into cache then refresh entire page
      injectLocalDevelopmentTokens();
      window.location.reload();
    } else {
      // redirect to MSAL login
      instance.loginRedirect({
        scopes: scopes,
      });
    }
  };

  const handleSignout = (e) => {
    setVisible(false);
    // GROWTH: Handle for automatic sign outs
    shutdown();

    instance.logoutRedirect({
      postLogoutRedirectUri: 'https://www.claconnect.com/',
    });
  };

  const handleClientHQ = () => {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = 'http://www.claconnect.com/clienthq';
    } else {
      window.location.href = 'http://cla.admin.onistaged.com/clienthq';
    }
  };

  const tempLoadData = () => {
    setVisible(false);
    if (isPractitioner || isAdmin) {
      validateCurrentCard();
      dispatch(ACTION.setCurrentCardKey(null));
      history.push(NAV.PRACTITIONERS_DASHBOARD);
    }
  };

  const buildActiveReturn = () => {
    return (
      <div className="activeReturnContainerMobile">
        <div
          className={
            activeReturn
              ? !isPracDash
                ? 'headerMenuTextSelectedMobile menuTextMobile'
                : 'headerMenuTextMobile menuTextMobile'
              : 'inactiveHeaderMenuTextMobile menuTextMobile'
          }
          id="dashboardMobile"
          onClick={activeReturn ? () => goBack() : () => {}}
          style={{ marginRight: '55px' }}
        >
          Active Client
        </div>
      </div>
    );
  };

  return (
    <div className="navbarMobile">
      <div className="navbarMenuMobile">
        <img
          className="site-logo-mobile"
          alt="CLA Navy colored logo"
          src={IMG.CLA_NAVY_LOGO}
          onClick={() => (!isDashboard ? goBack() : setVisible(false))}
        />

        <Button
          id="menu-button-mobile"
          className="menuButtonMobile"
          onClick={() => setVisible(!visible)}
        >
          <TextIconSpacing
            icon={
              !visible ? (
                <MenuSVGIcon className="menuItemButtonIcon" />
              ) : (
                <CloseSVGIcon className="menuItemButtonIcon" />
              )
            }
            iconAfter
          />
        </Button>

        {visible && (
          <div className="menuItemContainer">
            <Divider className="menuDivider" />
            <div
              key="dash-item-dashboardMobile"
              data-testid="dashMenuShowAll"
              className="dashboardSubMenuDropdownItem"
            >
              {(isPractitioner || isAdmin) && isPracDash
                ? buildActiveReturn()
                : isAuthenticated && (
                    <>
                      <div
                        className={
                          location.pathname === '/dashboard' ||
                          (location.pathname === '/' &&
                            !isPractitioner &&
                            !isAdmin)
                            ? active
                            : inactive
                        }
                        onClick={() =>
                          !isDashboard ? goBack() : setVisible(!visible)
                        }
                        id="dashboardMobile"
                      >
                        1040 Dashboard
                      </div>
                    </>
                  )}
            </div>
            <div
              key="dash-item-exchange"
              data-testid="dashMenuShowAll"
              className="dashboardSubMenuDropdownItem"
            >
              <div
                className={
                  location.pathname === '/practitionersDashboard' ||
                  (location.pathname === '/' && (isPractitioner || isAdmin))
                    ? active
                    : inactive
                }
                onClick={() => handleClientHQ()}
                id="practitionersDashboard"
              >
                Client HQ
              </div>
            </div>
            <div
              key="dash-item-exchange"
              data-testid="dashMenuShowAll"
              className="dashboardSubMenuDropdownItem"
            >
              {isPractitioner || isAdmin ? (
                <div
                  className={
                    location.pathname === '/practitionersDashboard' ||
                    (location.pathname === '/' && (isPractitioner || isAdmin))
                      ? active
                      : inactive
                  }
                  onClick={() => tempLoadData()}
                  id="practitionersDashboard"
                >
                  1040 Exchange Manager
                </div>
              ) : (
                <></>
              )}
            </div>

            <Divider className="menuDivider" />
            <div
              key="dash-item-login"
              onClick={isAuthenticated ? handleSignout : handleSignin}
            >
              <TextIconSpacing>
                <span className="menuTextMobileLogIn">
                  {isAuthenticated ? 'Sign Out' : 'Sign In'}
                </span>
              </TextIconSpacing>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeaderMenu;
