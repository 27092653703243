import { useEffect, useState, useRef } from 'react';
import { CloseSVGIcon } from '@react-md/material-icons';
import {
  Grid,
  GridCell,
  Divider,
  Button,
} from 'react-md';

import DashboardSubMenu from '@components/header/dashboardSubMenu';
import DashboardGrid from '@components/dashboard/dashboardGrid';
import { setBannerDismissed, setToolbarTitle } from '@utilities/actions/global';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import * as STRING from '@utilities/constants/strings';
import OrganizerUploads from '@components/OrganizerUploads';
import api from '@utilities/claApi';
import TaxReturnBanner from './taxReturnBanner';
import TaxReturnBannerMobile from './taxReturnBannerMobile';
import './dashboard.scss'
import useAdaptive from '@utilities/hooks/useAdaptive';
import CloseIcon from '@components/closeIcon';

const Dashboard = () => {
  const { dispatch, updateCard, selectState, REDUX, INPUTSTATE } = usePageFramework();
  const dashboard = selectState(REDUX.DASHBOARD);
  const isFutureYear = selectState(REDUX.IS_FUTURE_YEAR);
  const categories = dashboard ? dashboard.filter((category) => category.title !== 'Opportunity Services') : [];
  const inputState = selectState(REDUX.IS_PRACTITIONER) || selectState(REDUX.IS_ADMIN) ? INPUTSTATE.PRACTITIONER_INPUT : INPUTSTATE.USER_INPUT;

  // use to checked if user already login
  const taxYear = selectState(REDUX.YEAR);
  const organizerId = selectState(REDUX.ORGANIZER_ID);
  const taxpayerEmail = selectState(REDUX.TAXPAYER_EMAIL);
  const spouseEmail = selectState(REDUX.SPOUSE_EMAIL);
  const taxReturnStatus = selectState(REDUX.TAX_RETURN_STATUS);
  const bannerDismissed = selectState(REDUX.BANNER_DISMISSED);
  const activeReturn = selectState(REDUX.ACTIVE_RETURN);
  const formStatus = activeReturn?.formStatus;
  const isPriorYear = taxYear < currentYear;
  const { isLaptopOrDesktop, isDesktopDevice } = useAdaptive();
  const isAdmin = selectState(REDUX.IS_ADMIN);
  const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
  const organizer = selectState(REDUX.SELECTED_ORGANIZER);

  const [displayUploadInstructionBanner, setDisplayUploadInstructionBanner] = useState(true);

  useEffect(() => {
    dispatch(setToolbarTitle(STRING.DASHBOARD));
    updateCard();
  });

  const bannerRef = useRef(null);
  const closeUploadInstructionBanner = () => {
    if (bannerRef.current) {
      // Trigger collapsing animation by setting max-height and opacity with a delay
      bannerRef.current.style.maxHeight = '0';
      bannerRef.current.style.opacity = '0';
      setTimeout(() => setDisplayUploadInstructionBanner(false), 500);
      dispatch(setBannerDismissed(true));
    }
  };

  useEffect(() => {
    if (!displayUploadInstructionBanner && bannerRef.current) {
      // Trigger collapsing animation by setting max-height
      bannerRef.current.style.maxHeight = '0';
      bannerRef.current.style.opacity = '0';
    } else if (displayUploadInstructionBanner && bannerRef.current) {
      bannerRef.current.style.maxHeight = '118px';
      bannerRef.current.style.opacity = '1';
    }
  }, [displayUploadInstructionBanner]);

  useEffect(() => {
    if (inputState === INPUTSTATE.PRACTITIONER_INPUT || formStatus === STRING.COMPLETED_TEXT || isFutureYear || isPriorYear || !displayUploadInstructionBanner || bannerDismissed) {
      closeUploadInstructionBanner();
    } else {
      setDisplayUploadInstructionBanner(true);
    }
  }, [formStatus, inputState, isFutureYear, isPriorYear, displayUploadInstructionBanner, bannerDismissed]);

  const handleClose = async () => {
    const payload = { bannerDismissed: true }
    await api.put(`/organizers/${organizerId}`, payload);
    closeUploadInstructionBanner();
  }

  const renderUploadInstructionBanner = () =>
    <>
      <div
        ref={bannerRef}
        className='dashboardNotificationBanner'
      >
        {(!isLaptopOrDesktop) && <span className='dashboardNotificationBannerTitle'>Notification:</span>}
        <Button
          id="icon-button-close"
          data-testid="icon-button-close"
          buttonType="icon"
          theme="clear"
          aria-label="close"
          className="entryExperienceModalCloseBtn"
          onClick={handleClose}
        >
          {isLaptopOrDesktop ? <CloseSVGIcon className="closeIcon" /> : <CloseIcon className="closeIcon" />}
        </Button>
        <div className='dashboardNotificationBannerText'>
          Upload your documents and complete required cards. Don't forget to click "Submit to CLA"
          above when you are finished. If additional information is needed, your CLA team will contact
          you.
        </div>
      </div>
    </>;

  const taxReturnStatusProps = {
    taxReturnStatus: organizer ? organizer.taxReturnStatus : taxReturnStatus,
    isAdmin,
    isPractitioner
  };

  const taxReturnBanner = !isLaptopOrDesktop ? TaxReturnBannerMobile({ ...taxReturnStatusProps }) : TaxReturnBanner({ ...taxReturnStatusProps });

  const banner = taxReturnBanner
    ?? (formStatus !== STRING.COMPLETED_TEXT && displayUploadInstructionBanner && !bannerDismissed ? renderUploadInstructionBanner() : null);

  return (
    <>
      {banner}
      {
        isLaptopOrDesktop &&
        <Grid style={{ marginLeft: '30px', marginRight: '30px' }}>
          <p>
            {taxpayerEmail ? <><span style={{ fontWeight: "bold", marginLeft: '60px' }}>Taxpayer:</span>{taxpayerEmail}</> : null}
            {spouseEmail ? <><span style={{ fontWeight: "bold", marginLeft: "20px" }}>Spouse:</span>{spouseEmail}</> : null}
          </p>
          <GridCell colSpan={12} className="categoryCell">
            <GridCell style={{ width: '94%', position: 'relative', margin: '0 auto', marginBottom: '-30px' }} colSpan={8} id="dashboardCategoryDivider">
              <Divider />
            </GridCell>
          </GridCell>
        </Grid>
      }
      <OrganizerUploads style={{ top: '30px' }} />
      {isFutureYear ?
        <div className='formBuilderContainer futureContentContainer'>
          <div className='futureHeadText'>Your future tax year has not yet been created.</div>
          <div className='uploadText'>Upload files for your future tax year above.</div>
          <div className='contentText'>You can upload applicable documents in the Document Manager tab, or upload in bulk above.</div>
          <div className='contentText'>For the current tax year, please change the tax year in the dropdown selection above to upload files.</div>
        </div> :
        <>

          <div className="dashboardSize">
            {isDesktopDevice && <DashboardSubMenu />}
            <DashboardGrid categories={categories} dashboardVersion={organizer?.dashboardVersion} />
          </div>
        </>
      }
    </>
  );
};

export default Dashboard;