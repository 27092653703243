export const EXCHANGE_1040 = '1040 Exchange';
export const DASHBOARD_KEY = 'clientDashboard';
export const DASHBOARD = 'Dashboard';
export const TAXES = 'Taxes';
export const COMPANY_NAME = 'Company Name';
export const SUBMIT_TEXT = 'Submit to CLA';
export const COMPLETED_TEXT = 'Completed';
export const EXCHANGE_MANAGER_1040 = '1040 Exchange Manager';
export const NOT_STARTED_TEXT = 'Not Started';
export const SUMMARY_PDF = 'Summary PDF';
export const NOT_APPLICABLE = 'Not Applicable';
export const DOWNLOAD_CHECKLIST = 'Download Checklist'
export const CHECKLIST = 'Checklist'
export const EMAIL_CHECKLIST = 'Email Checklist'
export const ESIGNATURE_STATUS_DETAILS = 'eSignature Status Details'

// Common
export const DOWNLOAD_BUTTON_TEXT = 'Download';

//Categories
export const REQUIRED_INFORMATION = 'Required Information';
export const OPTIONAL_INFORMATION = 'Optional Information';
export const RECOMMENDED_INFORMATION = 'Recommended Information';
export const OPPORTUNITY_SERVICES = 'Opportunity Services';
export const YOUR_TAX_RETURN = 'Your Tax Return';

//Forms Cards
export const ABOUT_YOU = 'About You';
export const DEPENDENTS = 'Dependents';
export const ABOUT_YOUR_YEAR = 'About Your Year';
export const REFUND_PAYMENT_PREFERENCES = 'Refund/Payment Preferences';
export const TAX_PAYMENTS = 'Tax Payments';
export const FOREIGN_ASSETS = 'Foreign Assets';

export const WAGES = 'Wages (W-2)';
export const INVESTMENT_INCOME = 'Investment Income';
export const RETIREMENT_INCOME = 'Retirement Income';
export const BUSINESS_INCOME = 'Business Income (Schedule C)';
export const RENTAL_INCOME = 'Rental Property Income (Schedule E)';
export const FARM_INCOME = 'Farm Income (Schedule F)';
export const PASSTHROUGH_INCOME = 'Passthrough Income (K-1)';
export const MISC_INCOME = 'Other Sources of Income';
export const FOREIGN_INCOME = 'Foreign Income';

export const RETIREMENT_CONTRIBUTIONS = 'Retirement Contributions';
export const MEDICAL_EXPENSES = 'Medical Expenses';
export const PROPERTY_STATE_TAXES = 'Property Taxes';
export const MORTGAGE_INVESTMENT = 'Mortgage or Other Interest';
export const CONTRIBUTIONS = 'Charitable Contributions';
export const DEPENDENT_CARE = 'Child / Dependent Care';
export const HIGHER_EDUCATION = 'Higher Education';
export const ENERGY_EFFICIENT = 'Energy Efficient Purchase';
export const HOUSEHOLD_EMPLOYMENT = 'Household Employment Payment';
export const OTHER_DEDUCTIONS = 'Other Deductions';

export const INVESTMENT_PLANNING = 'Investment Planning';
export const FINANCIAL_PLANNING = 'Tax and Financial Planning';
export const INSURANCE_PLANNING = 'Insurance Planning';
export const OWNER_TRANSITION = 'Owner Transition Services';

//lookups
export const DROP_DOWN_DEFAULT_VALUE = ' ';
export const DROP_DOWN_DEFAULT_NAME = ' ';

export const NAME_AB = 'Alberta';
export const NAME_BC = 'British Columbia';
export const NAME_MB = 'Manitoba';
export const NAME_NB = 'New Brunswick';
export const NAME_NL = 'Newfoundland and Labrador';
export const NAME_NS = 'Nova Scotia';
export const NAME_NT = 'Northwest Territories';
export const NAME_NU = 'Nunavut';
export const NAME_ON = 'Ontario';
export const NAME_PE = 'Prince Edward Island';
export const NAME_QC = 'Québec';
export const NAME_SK = 'Saskatchewan';
export const NAME_YT = 'Yukon';

//subtitle
export const SCHED_J = 'Sched J';
export const SCHED_D = 'Sched D';
export const SCHED_BM = 'Sch B & Sch M';
export const SCHED_G = 'Sched G';
export const SCHED_F = 'Sched F';
export const SCHED_C = 'Sched C';
export const SCHED_I = 'Sched I';
export const SCHED_N = 'Sched N';
export const SCHED_A = 'Sched A';
export const SCHED_L = 'Sched L';
export const SCHED_R = 'Sched R';
export const SCHED_K = 'Sched K';
export const SCHED_H = 'Sched H';

//Error Message
export const GREATER_THAN_W2 = 'Bonus + Other cannot be greater than W-2';

export const NOT_AVAILABLE_LABEL = 'N/A';

export const ACCOUNT_TYPE_CHECKING = 'Checking';
export const ACCOUNT_TYPE_SAVINGS = 'Savings';

export const TAX_TYPE = 'TAX_TYPE';
export const TAX_TYPE_ADDITIONAL = '1';
export const TAX_TYPE_ESTIMATED = '5';

//Bulk Upload Titles and subTitles
export const WAGES_BULK_UPLOAD_TITLE = 'Please upload your summary of Wages for the year and/or complete the table below.';
export const WAGES_BULK_UPLOAD_SUBTITLE = 'Business income…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet.';
export const CHARITY_CONTRIBUTIONS_BULK_UPLOAD_TITLE = 'Please upload your summary of charitable contributions for the year and/or complete the table below.';
export const CHARITY_CONTRIBUTIONS_BULK_UPLOAD_SUBTITLE = 'Contributions made from a donor-advised fund (DAF)and/orcontributions made directly from an IRA (QCD) should not be included here as a charitable contribution.';
export const BUSINESS_INCOME_BULK_UPLOAD_TITLE = 'Please upload your summary of Business Income for the year and/or complete the table below.';
export const BUSINESS_INCOME_BULK_UPLOAD_SUBTITLE = 'Business income…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet.';
export const FARM_INCOME_BULK_UPLOAD_TITLE = 'Please upload your summary of Farm Income for the year and/or complete the table below.';
export const FARM_INCOME_BULK_UPLOAD_SUBTITLE = 'Farm income…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet.';
export const RENTAL_INCOME_BULK_UPLOAD_TITLE = 'Please upload your summary of Rental Income for the year and/or complete the table below.';
export const RENTAL_INCOME_BULK_UPLOAD_SUBTITLE = 'Rental income…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet.';
export const PASSTHROUGH_INCOME_BULK_UPLOAD_TITLE = 'Please upload your summary of Passthrough Income for the year and/or complete the table below.';
export const PASSTHROUGH_INCOME_BULK_UPLOAD_SUBTITLE = 'Passthrough income…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet.';
export const MEDICAL_EXPENSE_BULK_UPLOAD_TITLE = 'Please upload your summary of Medical Expenses for the year and / or complete the table below.';
export const MEDICAL_EXPENSE_BULK_UPLOAD_SUBTITLE = '';

//inlineUploadButton sectionName for file uploading on azure blob storage
export const FEDERAL_2020_TAX_PAYMENTS_UPLOAD = '2020FederalTaxPaymentsUpload';
export const STATE_2020_TAX_PAYMENTS_UPLOAD = '2020StateTaxPaymentsUpload';

export const WAGES_W2_UPLOAD = 'W2Upload';
export const INVESTMENT_INCOME_1099INT_UPLOAD = 'investmentIncome1099INT1099OIDUpload';
export const INVESTMENT_INCOME_NO1099INT_UPLOAD = 'investmentIncomeNo1099Upload';
export const INVESTMENT_INCOME_1099DIV_UPLOAD = 'investmentIncome1099DIVidendUpload';
export const INVESTMENT_INCOME_BROKERAGE_UPLOAD = 'investmentIncomeBrokerageStatementConsolidated1099Upload';
export const INVESTMENT_INCOME_OTHERS_UPLOAD = 'investmentIncomeOtherTypesUpload';
export const RETIREMENT_INCOME_SOCIAL_SECURITY_UPLOAD = 'retirementIncomeSocialSecurityBenefitsUpload';
export const RETIREMENT_INCOME_RETIREMENT_PLAN_UPLOAD = 'retirementIncomeRetirementPlanDistributionsUpload';
export const OTHER_INCOME_TAX_REFUNDS_UPLOAD = 'otherIncomeStateAndLocalIncomeTaxRefundsIRS1099GUpload';

export const OTHER_INCOME_UNEMPLOYMENT_UPLOAD = 'otherIncomeUnemploymentCompensationUpload';
export const OTHER_INCOME_GAMBLING_WINNINGS_UPLOAD = 'otherIncomeGamblingWinningsW2GUpload';
export const OTHER_INCOME_GAMBLING_LOSSES_UPLOAD = 'otherIncomeGamblingLossesUpload';
export const OTHER_INCOME_ALIMONY_UPLOAD = 'otherIncomeAlimonyReceivedUpload';
export const PROPERTY_TAXES_PAID_UPLOAD = 'propertTaxesPaidUpload';
export const MORTGAGE_INTEREST_FIN_INST_UPLOAD = 'mortgageInterestPaidToFinancialInstitutionsUpload';
export const MORTGAGE_INTEREST_OTHER_MORTGAGE_UPLOAD = 'mortgageInterestPaidNotToFinancialInstitutionsUpload';
export const MORTGAGE_INTEREST_INVESTMENT_INT_UPLOAD = 'mortgageInterestInvestmentInterestUpload';
export const RETIREMENT_CONT_INDIVIDUAL_ACCOUNTS_UPLOAD = 'retirementContributionsIndividualAccountsUpload';
export const RETIREMENT_CONT_SELF_EMPLOYED_UPLOAD = 'retirementContributionsSelfEmployedPlansUpload';
export const DEPENDENT_CARE_UPLOAD = 'childDependentCareCreditUpload';
export const HIGHER_EDUCATION_1098T_UPLOAD = 'higherEducationTuitionFeesAndQualifiedExpenses1098TUpload';

export const HIGHER_EDUCATION_TUITION_PLAN_CONT_UPLOAD = 'higherEducationQualifiedTuitionContribution529PlansUpload';
export const HIGHER_EDUCATION_1099Q_UPLOAD = 'higherEducationPaymentsIRS1099QUpload';
export const ENERGY_EFFICIENT_RESIDENTIAL_PROPERTY_UPLOAD = 'energyEfficientResidentialPropertyUpload';
export const ENERGY_EFFICIENT_RENEWABLE_ENERGY_UPLOAD = 'energyEfficientResidentialRenewableEnergyTaxUpload';
export const ENERGY_EFFICIENT_ALT_VEHICLE_UPLOAD = 'energyEfficientMotorVehicleUpload';
export const HOUSEHOLD_EMPLOYMENT_TAXES_UPLOAD = 'householdEmploymentTaxesUpload';
export const STATE_UNEMPLOYMENT_UPLOAD = 'stateUnemploymentOrInsuranceContributionsUpload';

export const CHARITABLE_CASH_CONT_UPLOAD = 'charitableCashContributionsReceiptsUpload';
export const CHARITABLE_NONCASH_CONT_UPLOAD = 'charitableNonCashContributionsReceiptsUpload';
export const CHARITABLE_STOCK_CONT_UPLOAD = 'charitableStockContributionsReceiptsUpload';
export const CHARITABLE_VOLUNTEER_MILES_UPLOAD = 'charitableVolunteerMilesReceiptsUpload';

export const OTHER_DEDUCTIONS_EDUCATOR_UPLOAD = 'otherDeductionsEducatorExpenseUpload';
export const OTHER_DEDUCTIONS_HSA_CONT_UPLOAD = 'otherDeductionsHSAContributions5948SAUpload';
export const OTHER_DEDUCTIONS_HSA_DIST_UPLOAD = 'otherDeductionsHSADistributions1099SAUpload';
export const OTHER_DEDUCTIONS_STUDENT_LOAN_UPLOAD = 'otherDeductionsStudentLoan1098EUpload';
export const OTHER_DEDUCTIONS_ALIMONY_PAID_UPLOAD = 'otherDeductionsAlimonyPaidUpload';
export const OTHER_DEDUCTIONS_AF_MILES_UPLOAD = 'otherDeductionsArmedForcesMovingMilesUpload';
export const OTHER_DEDUCTIONS_AF_MOVING_UPLOAD = 'otherDeductionsArmedForcesMovingExpensesUpload';
export const OTHER_DEDUCTIONS_EMPLOYEE_BUSINESS_EXPENSE_UPLOAD = 'otherDeductionsEmployeeBusinessExpenseUpload';

export const SCHEDC_EQUIPMENT_UPLOAD = 'scheduleCBusinessEquipmentandPropertyUpload';
export const SCHEDE_1099MISC_1099K_UPLOAD = 'scheduleEProperty1099Misc1099KFormsUpload';
export const SCHEDE_EQUIPMENT_UPLOAD = 'scheduleERentalEquipmentandPropertyUpload';
export const SCHEDF_EQUIPMENT_UPLOAD = 'scheduleFFarmEquipmentandPropertyUpload';

export const FOREIGN_ASSETS_PENSION_UPLOAD = 'foreignAssetsNonUsPensionUpload';
export const FOREIGN_ASSETS_ACCOUNTS_UPLOAD = 'foreignAssetsBankAccountUpload';
export const FOREIGN_ASSETS_INVESTMENTS_UPLOAD = 'foreignAssetsInvestmentUpload';
export const FOREIGN_ASSETS_LIFE_INSURANCE_UPLOAD = 'foreignAssetsLifeInsuranceUpload';
export const FOREIGN_ASSETS_ENTITY_OWNERSHIP_UPLOAD = 'foreignAssetsEntityOwnershipUpload';

export const FOREIGN_INCOME_TRAVEL_WORKDAY_UPLOAD = 'foreignIncomeTravelWorkdayUpload';
export const FOREIGN_EMPLOYMENT_UPLOAD = 'foreignIncomeEmploymentUpload';
export const FOREIGN_HOUSING_UPLOAD = 'foreignIncomeHousingUpload';
export const FOREIGN_SEPARATE_HOME_INFO_UPLOAD = 'foreignSeparateHomeInfoUpload';
export const FOREIGN_OCCUPANT_DETAILS_UPLOAD = 'foreignOccupantDetailsUpload';
export const FOREIGN_INCOME_ADD_ANOTHER_ACCOUNT = 'Additional Foreign Account';
export const FOREIGN_INCOME_TAXES_UPLOAD = 'foreignIncomeTaxesUpload';

export const CLA_ENTERED = 'CLA Entered';
export const PRIOR_YEAR = 'Prior Year';
export const OPTIONAL = 'Optional';

export const RETIREMENT_TYPE_SIMPLE = 'SIMPLE';
export const RETIREMENT_TYPE_SEP = 'SEP IRA';
export const RETIREMENT_TYPE_BENEFIT = 'Defined Benefit Plan';

export const ENTRY_EXP_WAGES = 'Earned Wages (W-2)';
export const ENTRY_EXP_INVESTMENT = 'Received Investment Income';
export const ENTRY_EXP_K1 = 'Received a K-1';
export const ENTRY_EXP_MISC_INCOME = 'Had Other Sources of Income';
export const ENTRY_EXP_RETIREMENT_INCOME = 'Received Retirement Income';
export const ENTRY_EXP_SCHEDC = 'Had Business Income (Schedule C)';
export const ENTRY_EXP_SCHEDE = 'Owned Rental Property (Schedule E)';
export const ENTRY_EXP_SCHEDF = 'Owned a Farm (Schedule F)';
export const ENTRY_EXP_FOREIGN_INCOME = 'Earned Foreign Income';
export const ENTRY_EXP_FOREIGN_ASSETS = 'Held Foreign Assets';

export const ENTRY_EXP_MEDICAL_EXPENSES = 'Paid Medical Expenses';
export const ENTRY_EXP_PROPERTY_TAX = 'Paid Property Tax';
export const ENTRY_EXP_MORTGAGE_INTEREST = 'Paid Mortgage or Other Interest';
export const ENTRY_EXP_RETIREMENT_CONTRIBUTION = 'Contributed to a Retirement Account';
export const ENTRY_EXP_CHARITABLE = 'Supported Charitable Organizations';
export const ENTRY_EXP_CHILD_CARE = 'Paid for Child/Dependent Care';
export const ENTRY_EXP_HIGHER_EDUCATION = 'Paid Higher Education';
export const ENTRY_EXP_ENERGY_EFFICIENT = 'Made an Energy Efficient Purchase';
export const ENTRY_EXP_HOUSEHOLD = 'Paid Household Employees';
export const ENTRY_EXP_OTHER_DEDUCTIONS = 'Had Other Deductions';

export const ENTRY_EXP_INVESTMENT_PLANNING = 'Investment Planning';
export const ENTRY_EXP_FINANCIAL_PLANNING = 'Tax and Financial Planning';
export const ENTRY_EXP_INSURANCE_PLANNING = 'Insurance Planning';
export const ENTRY_EXP_OWNER_TRANSITION = 'Owner Transition Services';

export const FOREIGN_ACC_TYPE_OTHER = 'Other';
export const FOREIGN_ACC_TYPE_BANK = 'Bank';
export const FOREIGN_ACC_TYPE_SECURITIES = 'Securities';

export const DASHBOARD_SECTION_NAME = 'dashboardBulkUploads';
export const DASHBOARD_FILE_UPLOADS = 'File Uploads';
export const DASHBOARD_UPLOAD_SUBTITLE = 'Choose a file or drag it here.';
export const DASHBOARD_UPLOAD_SUBTITLE_MOBILE = 'Choose a file from your file folder to upload.';
export const QUICK_UPLOAD_TITLE = 'Quick Upload';
export const QUICK_UPLOAD_SUBTITLE = 'In a hurry, drag and drop your files here to organize later.';

export const FORM_SUBMIT_WARNING_TXT = 'Are you sure you want to submit? If you select yes, you will no longer be able to edit the form';

//Form Notes
export const NOTES_SUBTITLE = 'You have unsaved changes on the notes for CLA.';
export const NOTES_QUESTION = 'Are you sure you want to close this notes section?';
export const NOTES_CONFIRM = 'Confirming will lose your unsaved changes.';
export const NOTES_UPDATING = 'Please wait, updating notes...';
export const NOTES_SUCCESS = 'You have successfully saved your notes changes.';
export const NOTES_ERROR_SAVING = 'An unexpected error occured, \n Please try saving the notes again.';

export const NO_ACTIVE_CLIENT = 'No active client found';

export const MISCELLANEOUS = 'Misc';
export const NON_EMPLOYMENT_COMMISSION = 'NEC';

export const ON_FILE_OVERRIDE = 'ON FILE';

export const ORGANIZER_SUBMITTED = 'ORGANIZER_SUBMITTED';
export const UPLOAD_AFTER_LOCK = 'UPLOAD_AFTER_LOCK';
export const WEALTH_ADVISORY_INTEREST_EMAIL = 'WEALTH_ADVISORY_INTEREST_EMAIL';
export const AGREEMENT_ACCEPTANCE_DECLINED = 'AGREEMENT_ACCEPTANCE_DECLINED';

//invalid upload file names
export const INVALID_CHARS = `' ~ " # % & * : < > ? / \\ { | }`;
export const INVALID_FILES_MESSAGE_H3 = 'Files listed below have invalid names. Please fix and reupload:';
export const INVALID_FILES_MESSAGE_H4 = 'A file name can\'t contain any of the following characters:';
export const INVALID_FILES_MESSAGE_RENAME = `A file name can't contain any of the following characters: ' ~ " # % & * : < > ? / \\ { | }`;

//Submit Dialog texts
export const ARE_YOU_SURE = 'Are you sure?';
export const ERROR = 'Error';
export const BY_SELECTING = `By selecting 'Submit to CLA' this does the following:`;
export const SUBMIT_1 = '1. Your tiles are locked for editing. You will NOT be able to make any changes.';
export const SUBMIT_2 = '2. You can still upload files at any time.';
export const SUBMIT_MOBILE_1 = 'Your tiles are locked for editing. You will NOT be able to make any changes.';
export const SUBMIT_MOBILE_2 = 'You can still upload files at any time.';
export const GO_ABOUT_YOU = 'Go to About You';
export const GO_ABOUT_YOUR_YEAR = 'Go to About Your Year';
export const CLOSE = 'Close';
export const SAVE_CONT = 'No - Save and continue later';
export const SUBMIT = 'Submit to CLA';
export const YES = 'Yes - Submit to CLA';

//Upload Warning Dialog texts
export const WARNING = 'WARNING';
export const SKIP = 'Skip';
export const CANCEL = 'Cancel';
export const CANCEL_ALL = 'Cancel All';
export const REPLACE = 'Replace';
export const DECLINE = 'Decline';

// Welcome email
export const RESEND_CLIENT_WELCOME_EMAIL = 'Resend Welcome Email';

//Dependents
export const PROVIDER_HYPHEN = 'provider-';
export const NO_LONGER_DEPENDENT = 'No longer my dependent';
export const GENERAL = 'General';
export const BASIC_DATA = 'Basic Data';

export const ENGAGEMENT_AGREEMENT_DECLINED = 'Engagement Agreement Declined'

export const RENTAL_INCOME_ROYALTY_DISABLED_TEXT = 'If you also have royalty income you will need to enter that on another property.  If you need to add another property click the "add new" button near the top of the page.'

export const RENTAL_INCOME_RENTAL_DISABLED_TEXT = 'If you also have rental income you will need to enter that on another property.  If you need to add another property click the "add new" button near the top of the page.'

//Entry Experience Warning dialog
export const ENTRY_EXPERIENCE_DECLINE_WARNING = 'Warning';

// Downloaded files dialog text
export const DOWNLOADED_FILES_PROGRESS = 'Downloading files...';
export const DOWNLOADED_FILES_COMPLETED = 'Download complete!';
export const DOWNLOADED_FILES_ERRORED = 'Download error!';

//Email checklist dialog
export const SEND = 'Send';
export const NOT_SENT = 'Not Sent';
export const EMAIL_CHECKLIST_SENT_SUCCESS_TEXT = 'Checklist has been sent.';
export const EMAIL_CHECKLIST_SENT_FAILED_TEXT = 'Your email could not be sent at this time, please retry.';


//Key Statistics
//Checklist Not Downloaded Tile
export const CHECKLIST_NOT_DOWNLOADED_LABEL = 'Checklist Not Downloaded';
export const CHECKLIST_NOT_DOWNLOADED_KEY = 'checklistNotDownloaded';
export const ALL_CHECKLIST_KEY = 'allChecklist';

//Awaiting Signature Tile
export const AWAITING_SIGNATURE_LABEL = 'Awaiting eSignature';
export const AWAITING_SIGNATURE_KEY = 'awaitingEsignatures';
export const All_SIGNATURE_KEY = 'allValidEsignatures';


export const DASHBOARD_VERSION = 2;

export const SWIMLANE_CARD_COUNT_LABEL = "Card";
export const SWIMLANE_CARD_COUNT_LABEL_PLURAL = "Cards";

// Tax Return
export const TAX_RETURN_DOWNLOAD_INACTIVE_TEXT = "Available to Download After Signing";
