import { SCREEN_SIZES } from '@utilities/constants/screenSizes';
import usePageFramework from './usePageFramework';
import { isDesktop, isMobile } from 'react-device-detect';

const useAdaptive = () => {
    const { REDUX, selectState } = usePageFramework();
	  const screenSize = selectState(REDUX.SCREEN_SIZE);

    return {
        isMobile: screenSize === SCREEN_SIZES.MOBILE || screenSize === SCREEN_SIZES.MOBILE_480,
        isMobile480: screenSize === SCREEN_SIZES.MOBILE_480,
        isTablet: screenSize === SCREEN_SIZES.TABLET,
        isLaptop: screenSize === SCREEN_SIZES.LAPTOP,
        isDesktop: screenSize === SCREEN_SIZES.DESKTOP,
        isLaptopOrDesktop: screenSize === SCREEN_SIZES.LAPTOP || screenSize === SCREEN_SIZES.DESKTOP,
        isDesktopDevice: isDesktop,
        isMobileDevice: isMobile
    }
};

export default useAdaptive;