// common modules
import { Button, Dialog } from 'react-md';

// custom modules
import '@components/dialog/customDialog/customDialog.css';
import usePageFramework from '@utilities/hooks/usePageFramework';

function CustomDialog(props) {
    const { REDUX, ACTION, dispatch, selectState } = usePageFramework();
    const title = selectState(REDUX.CUSTOM_DIALOG_TITLE);
    const message = selectState(REDUX.CUSTOM_DIALOG_MSG);
    const uploadProgress = selectState(REDUX.UPLOAD_PROGRESS_VISIBLE);
    const isCloseButtonHidden = selectState(REDUX.HIDE_CUSTOM_DIALOG_CLOSE_BUTTON) || false;
    const isMultipleUpload = selectState(REDUX.IS_MULTIPLE_UPLOAD) || false;
    const isMultipleUploadError = message?.type?.name === 'MultipleFileErrorsMessage';
    let className = 'WarningDialogContainer submitToClaSuccessDialog';

    if (isMultipleUploadError) {
        className += ' multipleFileErrorMessageDialog'
    }

    return (
        <Dialog
            id="custom-dialog"
            defaultFocus="first"
            data-testid='custom-dialog'
            visible={props.visible}
            onRequestClose={() => { }}
            modal={true}
            aria-labelledby="dialog-title"
            className={className}
            style={isMultipleUpload ? { width: '750px' } : null}
        >
            <div className='WarningDialogInnerContainer' >
                <div className="SuccessDialogTitle" style={isMultipleUpload ? { textAlign: 'left' } : null}>{title}</div>
                <div className="WarningDialogTextContainer">
                    <div className="WarningDialogText1 SuccessDialogText"> {message}</div>
                </div>
                {
                    !isCloseButtonHidden && <div className='successDialogButton'>
                        <Button className="formNotesSaveActive" id="dialog-close" data-testid='dialog-close'
                            onClick={() => {
                                dispatch(ACTION.setShowCustomDialog(false));
                                dispatch(ACTION.setDuplicateUploadFilesSkipped([]));

                                dispatch(ACTION.setIsMultipleUpload(false))

                                if (uploadProgress) dispatch(ACTION.setUploadProgressVisible(false));
                            }}
                        > Close
                        </Button>
                    </div>
                }
            </div>
            {/* <DialogHeader>
                <DialogTitle>{title}</DialogTitle>
            </DialogHeader>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogFooter>
                <Button id="dialog-close" onClick={() => { dispatch(ACTION.setShowCustomDialog(false)); } }>
                    Close
                </Button>
            </DialogFooter> */}
        </Dialog>
    );
}

export default CustomDialog;