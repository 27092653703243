import {
  Button,
  DialogHeader,
  DialogTitle,
  DialogContent,
  DialogFooter,
  Dialog
} from 'react-md';
import nextId from 'react-id-generator';
import * as IMG from '@utilities/constants/images';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { useState } from 'react';
import './agreementAcceptance.css';
import * as STR from '@utilities/constants/strings';
import api from '@utilities/claApi';
import useAdaptive from "@utilities/hooks/useAdaptive";

function AgreementAcceptance(props) {
  const { showLimitedExp, showGettingStarted, setIsAgreementAcceptance, setIsGettingStarted } = props;
  const { selectState, REDUX, organizerId } = usePageFramework();
  const isAdmin = selectState(REDUX.IS_ADMIN);
  const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
  const [isShowWarningDialog, setIsShowWarningDialog] = useState(false);
  const [isShowFinalWarn, setIsShowFinalWarn] = useState(false)
  const { isMobileDevice, isMobile480, isMobile, isLaptopOrDesktop } = useAdaptive();

  const taxpayerName = selectState(REDUX.TAXPAYER_NAME);
  const taxpayerSpouseName = selectState(REDUX.TAXPAYER_SPOUSE_NAME);
  const hasMultipleReturns = selectState(REDUX.HAS_MULTIPLE_RETURNS);
  const exchangeMobileText = taxpayerName ? '1040 Exchange,' : '1040 Exchange';

  const navigateDeclineURL = () => {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = 'http://www.claconnect.com/clienthq';
    } else {
      window.location.href = 'http://cla.admin.onistaged.com/clienthq';
    }
  };

  const handleAcceptAndContinueOnClick = async () => {

    try {
      const payload = { isTermsAgreed: true };
      await api.put(`/organizers/${organizerId}`, payload);
      if ((!isAdmin && !isPractitioner) && isMobileDevice) {
        showLimitedExp();
      } else {
        setIsAgreementAcceptance(false);
        showGettingStarted();
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleDeclineOnClick = () => {
    setIsShowWarningDialog(true);
  };

  const declineWarning = () => {
    setIsShowFinalWarn(true);
    const data = { type: STR.AGREEMENT_ACCEPTANCE_DECLINED, data: { id: organizerId } };
    api.post('/notifications', data);
    setTimeout(() => {
      navigateDeclineURL();
    }, 5000);
  };

  const cancelWarning = () => {
    setIsShowWarningDialog(false);
  };

  const handleBackToTaxpayerSelection = () => {
    setIsAgreementAcceptance(false);
    setIsGettingStarted(false);
  };

  return (
    <>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        {<img src={IMG.CLA_NAVY_LOGO} alt="CLA navy colored logo" className="entryHeaderSilIcon" style={{
          marginTop: '0px', width: '39px', height: '40.16px'
        }}></img>}
        <DialogHeader>
          <DialogTitle id="entry-experience-dialog-title" className={"entry-experience-title"}
            style={{
              lineHeight: '25px', fontSize: '25px', color: '#303E59'
            }}
          >
            Welcome to the {isMobileDevice ? <label>{exchangeMobileText}</label> : <span className="" style={{ lineHeight: '25px', color: '#303E59' }}>1040 Exchange,</span>}
            <p>{taxpayerName} {taxpayerSpouseName ? `and ${taxpayerSpouseName}` : null}</p>
          </DialogTitle>
        </DialogHeader>
      </div>
      <DialogContent className="entry-modal-content">
        <div className="entry-modal-main-div">

          <div className="entry-step-text-container">
            <div className={"entry-please-tell-us-about"} style={{ fontWeight: "600" }}>Engagement Agreement</div>
          </div>
          <ul className="entry-modal-ul">
            <li className="entry-modal-li">
              <h4 className={"entry-modal-li-h4"}>Re: Individual Income Tax Returns Engagement Agreement</h4>
              <p className={"entry-modal-li-p"}>
                We are pleased that you have engaged CliftonLarsonAllen LLP (“CLA,” “we,” “us,” and “our”) to prepare your federal
                and state (if applicable) individual income tax returns. This agreement confirms the scope, limitations, and
                related terms of our engagement for the year ended December 31, 2024.</p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}>Our responsibility to you</h4>
              <p className={"entry-modal-li-p"}>
                We will prepare your federal and state (if applicable) income tax returns and those of any of your dependents,
                if requested, in accordance with the applicable income tax laws. We will use our judgment in resolving questions
                where the law is unclear, and where there is reasonable authority, we will resolve questions in your favor whenever possible.
                We will not audit or otherwise verify the accuracy or completeness of the information we receive from you for
                the preparation of the returns, and our engagement cannot be relied upon to uncover errors or irregularities in
                the underlying information. We will furnish you (at your last known address) with a tax organizer to assist you
                in gathering and organizing the necessary information for us.</p>
              <p className={"entry-modal-li-p"}>
                If requested, we will also prepare 1099 information returns from information provided by you and transmit
                to federal and state taxing authorities on your behalf.</p>
              <p className={"entry-modal-li-p"}>
                If requested, we will also prepare personal property tax rendition (as applicable) in accordance with the applicable laws.</p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}>Your responsibilities</h4>
              <p className={"entry-modal-li-p"}>
                It is your responsibility to provide us with all the information needed to prepare complete and accurate
                returns. We will have no obligation to prepare the returns until you have provided such information to us. You
                agree to read and consider the content of our tax organizer when providing your information to us. We will
                prepare returns for the same states where you filed the preceding year unless you notify us to the contrary
                or a source document clearly indicates the need to file an additional state return. We will also prepare any state
                return(s) for any disregarded entities owned by you and disclosed to us that require a separate state filing. It is
                your responsibility to comply with all foreign jurisdiction filing requirements. We have no obligation to prepare
                returns for foreign jurisdictions.</p>
              <p className={"entry-modal-li-p"}>
                If you provide information after March 1, we may be unable to complete the return by the original filing
                deadline and may need to file an extension. If an extension is filed and information is not provided by
                September 1, we may be unable to complete your return(s) by the extended due date. Failure to timely file
                your return(s) or to file for an extension can result in penalties which can be substantial.</p>
              <p className={"entry-modal-li-p"}>
                It is important for you to identify any ownership OR signature authority over a foreign bank account or other
                foreign financial assets which includes but is not limited to foreign: stocks, mutual funds, partnerships, bonds,
                retirement accounts, estates, trusts, annuities, swaps, and derivatives. Failure to disclose penalties can be
                significant, starting at $10,000 and can be upwards of 50 percent of the value of the asset. Please provide
                account statements if you have any foreign account ownership or signature authority. Note that these rules do
                not apply to foreign investments held by U.S. mutual funds. In addition, ownership in a foreign business entity
                (association, corporation, disregarded entity, or partnership) could trigger additional U.S. foreign informational
                reporting requirements. These reporting requirements require the disclosure of ownership, financial
                information, and related-party transactions. Failure to properly disclose ownership, related-party transactions,
                and the required information could trigger a penalty of up to $25,000 per filing. We cannot be held responsible
                if you fail to identify or provide such information to us.</p>
              <p className={"entry-modal-li-p"}>
                For all nonattest services we may provide to you, including these tax services, you agree to oversee the services;
                make all personal decisions and perform all personal
                functions related to the services; evaluate the adequacy and results of the services performed; and accept
                responsibility for the results of the services. You are also responsible for ensuring that your data and records
                are complete and that you have received sufficient information to oversee the services. It is your responsibility
                to carefully review the returns that we prepare on your behalf before they are signed and submitted to tax
                authorities. We will advise you with regard to tax positions taken in the preparation of the tax returns, but the
                responsibility for the tax returns remains with you. Our services will be concluded upon the delivery to you of
                your tax returns for your review and filing with all appropriate tax authorities.
              </p>
              <p className={"entry-modal-li-p"}>
                You are responsible for providing us with all the information needed to prepare complete and accurate 1099
                information returns. We will have no obligation to prepare 1099 information returns until you have provided
                such information to us. You are responsible for determining which vendors are required to receive 1099
                information returns. Our 1099 preparation services will include electronically transmitting 1099 information
                returns to federal and state taxing authorities on your behalf with your written approval and authorization (an
                email is acceptable documentation).
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}>Beneficial ownership information (BOI) reporting</h4>
              <p className={"entry-modal-li-p"}>
                Beginning in 2024 under the Corporate Transparency Act (CTA), certain entities organized in the U.S. (including
                entities that are disregarded for federal income tax purposes) and foreign entities doing business in the U.S.
                are required to report information to the Financial Crimes Enforcement Network (FinCEN) as to their beneficial
                ownership. The report must provide each beneficial owner, each company applicant and other required
                information. Entities subject to the beneficial ownership information (BOI) reporting include a corporation,
                limited liability company, or any other entity created by the filing of a document with the secretary of state or
                similar office under state, Tribal, or foreign country law. Note that some entities are exempt from the BOI
                reporting requirements (including many nonprofits and certain large operating companies).</p>
              <p className={"entry-modal-li-p"}>
                <span style={{ fontWeight: "bold" }}>It is your responsibility to prepare and submit any BOI report to FinCEN that is required under the CTA.</span> We
                have no obligation to identify any filing requirements or provide any services related to BOI reporting.
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}>Withholdings</h4>
              <p className={"entry-modal-li-p"}>
                Unless you are subject to adequate income tax withholdings or we agree to compute quarterly estimated tax
                payments, we will determine the requirements for and the amounts of your quarterly estimated tax payments,
                if applicable, for the next year based on “safe harbor” payments to avoid underpayment penalties.</p>
              <p className={"entry-modal-li-p"}>
                We will assume that tax withholdings will be the same as during the year prior unless you provide us with
                additional information about withholdings.
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}>Tax examinations</h4>
              <p className={"entry-modal-li-p"}>
                All returns are subject to potential examination by the IRS and state taxing authorities. In the event of an
                examination, we will be available, at your request, to assist or represent you. Services in connection with tax
                examinations are not included in our fee for preparation of your returns. Our fee for such services will be billed
                to you separately, along with any direct costs.
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}>Record retention</h4>
              <p className={"entry-modal-li-p"}>
                You are responsible for retaining all documents, records, canceled checks, receipts, or other evidence in
                support of information and amounts reported on your tax returns. These items may be necessary in the event
                the taxing authority examines or challenges your returns. These records should be kept for at least seven
                years. Your copy of the tax returns should be retained indefinitely.
              </p>
              <p className={"entry-modal-li-p"}>
                If carryover item(s) exist (e.g., capital loss, net operating loss, tax credits, etc.), you should retain the
                supporting records related to the carryover item(s) until the item has either been utilized (and the statute of
                limitations associated with the year of utilization has expired) or the carryforward period has expired.
              </p>
              <p className={"entry-modal-li-p"}>
                In preparing the tax returns, we rely on your representation that you understand and have complied with
                these documentation requirements. You are responsible for the proper recording of transactions, for the
                safeguarding of assets, and for the substantial accuracy of your financial records.
              </p>
              <p className={"entry-modal-li-p"}>
                All the records you provide us to prepare your tax returns will be returned to you after our use. Our working
                papers, including any copies of your records that we chose to make and the tax organizer that you complete,
                are our property and will be retained by us in accordance with our established records retention policy. This
                policy states, in general, that we will retain our working papers for a period of seven years. After this period
                expires, our working papers and files will be destroyed. Furthermore, physical deterioration or catastrophic
                events may shorten the time our records are available. The working papers and files of our firm are not a
                substitute for your records.
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}>Tax consulting services</h4>
              <p className={"entry-modal-li-p"}>
                This engagement agreement also covers tax consulting services that may arise for which you seek our
                consultation and advice, both written and oral, that are not the subject of a separate engagement agreement.
                These additional services are not included in our fees for the preparation of the federal and state tax returns.
              </p>
              <p className={"entry-modal-li-p"}>
                We will base our tax analysis and conclusions on the facts you provide to us and will not otherwise verify those
                facts. We will review the applicable tax law, tax regulations, and other tax authorities, all of which are subject
                to change. At your request, we will provide a memorandum of our conclusions. Written advice provided by us
                is for your information and use only and is not to be provided to any third party without our express written
                consent.
                <p className={"entry-modal-li-p"}>
                  Unless we are separately engaged to do so, we will not continuously monitor and update our advice for
                  subsequent changes or modifications to the tax law and regulations, or to the related judicial and
                  administrative interpretations.
                </p>
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}> Communications and confidentiality
              </h4>
              <p className={"entry-modal-li-p"}>
                The Internal Revenue Code contains a limited privilege for confidentiality of tax advice between you and our
                firm. In addition, the laws of some states likewise recognize a confidentiality privilege for some accountant-
                client communications. You understand that CLA makes no representation, warranty or promise, and offers no
                opinion with respect to the applicability of any confidentiality privilege to any information supplied or
                communications you have with us, and, to the extent that we follow instructions from you to withhold such
                information or communications in the event of a request from a third party (including a subpoena, summons or
                discovery demand in litigation), you agree to hold CLA harmless should the privilege be determined not to apply to
                particular information or communications.
              </p>
              <p className={"entry-modal-li-p"}>
                CLA will not disclose any of your confidential, proprietary, or privileged information to any persons without
                your authorization unless required by law. This confidentiality provision does not prohibit us from disclosing
                your information to one or more of our affiliated companies to provide services you have requested from us or
                from any such affiliated company. Any such affiliated company shall be subject to the same restrictions on the
                use and disclosure of your information as applied to us.
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}> <i>Subcontractors</i>
              </h4>
              <p className={"entry-modal-li-p"}>
                CLA may, at times, use subcontractors to perform services under this agreement, and they may have access to
                your information and records. Any such subcontractors will be subject to the same restrictions on the use of
                such information and records as apply to CLA under this agreement.
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}> <i>Technology</i>
              </h4>
              <p className={"entry-modal-li-p"}>
                CLA may, at times, use third-party software applications to perform services under this agreement. CLA can
                provide a copy of the application agreement at your request. You acknowledge the software vendor may have
                access to your data.
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}> Limitations
              </h4>
              <p className={"entry-modal-li-p"}>
                Our role is strictly limited to the services described in this agreement, and we offer no assurance as to the
                results or ultimate outcomes of any services or of any decisions that you may make based on our
                communications with you. You agree that it is appropriate to limit the liability of CLA, its partners, principals,
                directors, officers, employees, and agents (each a “CLA party”) and that this limitation of remedies provision is
                governed by the laws of the state of Minnesota, without giving effect to choice of law principles.
              </p>
              <p className={"entry-modal-li-p"}>
                You further agree that you will not hold CLA or any other CLA party liable for any claim, cost, or damage,
                whether based on warranty, tort, contract, or other law, arising from or related to the services provided under
                this agreement, the work product, or for any plans, actions, or results of any service, except to the extent
                authorized by this agreement. In no event shall any CLA party be liable to you for any indirect, special,
                incidental, consequential, punitive, or exemplary damages, or for loss of profits or loss of goodwill, costs, or
                attorney fees.
              </p>
              <p className={"entry-modal-li-p"}>
                The exclusive remedy available to you shall be the right to pursue claims for actual damages that are directly
                caused by acts or omissions that are breaches by a CLA party of our duties owed under this engagement
                agreement, but any recovery on any such claim shall not exceed the portion of the total fees actually paid by
                you to CLA that corresponds to the particular service(s) that give(s) rise to the claim (i.e., the specific service(s)
                that a CLA party performed in such a manner as to cause CLA to be liable to you).
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}> Time Limitation
              </h4>
              <p className={"entry-modal-li-p"}>
                The nature of our services makes it difficult, with the passage of time, to gather and present evidence that fully
                and fairly establishes the facts underlying any dispute that may arise between you and any CLA party. The
                parties (you and CLA) agree that, notwithstanding any statute or law of limitations that might otherwise apply
                to a dispute, including one arising out of this agreement or the services performed under this agreement, for
                breach of contract or fiduciary duty, tort, fraud, misrepresentation or any other cause of action or remedy, any
                action or legal proceeding by you against any CLA party must be commenced as provided below, or you shall
                be forever barred from commencing a lawsuit or obtaining any legal or equitable relief or recovery. An action
                to recover on a dispute shall be commenced within the shorter of these periods (“Limitation Period”):
              </p>
              <p className={"entry-modal-li-p"}>
                <ul style={{ listStyleType: "disc", padding: "20px" }}>
                  <li> For tax return preparation, separately within thirty-six (36) months after the date when we deliver any
                    final tax return(s) under this agreement to you on which the dispute is based, regardless of whether any
                    CLA party provides other services for you or relating to said return(s).</li>
                  <li>For tax consulting engagements, separately within thirty-six (36) months from the date of our last billing
                    for services on each consultation on which the dispute is based.</li>
                  <li>For all tax return and tax consulting engagements, within twelve (12) months from the date when you
                    terminate this or any other engagement of our services.</li>
                </ul></p>
              <p className={"entry-modal-li-p"}>
                The applicable Limitation Period applies and begins to run even if you have not suffered any damage or loss or
                have not become aware of the existence or possible existence of a dispute.</p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}> Fees
              </h4>
              <p className={"entry-modal-li-p"}>
                Our professional fees will be billed based on the degree of responsibility and contribution of the professionals
                working on the engagement. Our normal billing practice is to invoice 50% of the estimated professional
                services fees upon receipt of your tax return information, or upon the preparation of an application for an
                extension of time to file your tax return(s), if earlier. We will continue to periodically bill for our time as work
                progresses.
              </p>
              <p className={"entry-modal-li-p"}>
                Additional charges will apply if you request a paper copy of your return(s), your circumstances are complex,
                changes to the tax law occur, or unexpected circumstances require additional time. We may apply a 15%
                surcharge (based on prior year invoice) if you do not provide your tax return information by August 15. We will
                also bill for expenses (including internal and administrative charges) plus a technology and client support fee of
                five percent (5%) of all professional fees billed. Our invoices, including applicable state and local taxes, will be
                rendered each month as work progresses and are payable on presentation. In accordance with our firm
                policies, work may be suspended if your account becomes 60 days or more overdue and will not be resumed
                until your account is paid in full. If the account is not paid by September 1, we may be unable to complete your
                return(s) by the extended due date. If we elect to terminate our services for nonpayment, our engagement will
                be deemed to have been completed even if we have not issued the tax return(s). You will be obligated to
                compensate us for all time expended and related fees and to reimburse us for all out-of-pocket expenditures
                through the date of termination.
              </p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}> <i>Other fees</i>
              </h4>
              <p className={"entry-modal-li-p"}>
                You also agree to compensate us for any time and expenses, including time and expenses of legal counsel,
                we may incur in responding to discovery requests or participating as a witness or otherwise in any legal,
                regulatory, or other proceedings that we are asked to respond to on your behalf.</p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}> <i>Finance charges and collection expenses</i>
              </h4>
              <p className={"entry-modal-li-p"}>
                You agree that if any statement is not paid within 30 days from its billing date, the unpaid balance shall accrue
                interest at the monthly rate of one and one-quarter percent (1.25%), which is an annual percentage rate of
                15%. In the event that any collection action is required to collect unpaid balances due us, reasonable attorney
                fees and expenses shall be recoverable.</p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}> Termination of agreement
              </h4>
              <p className={"entry-modal-li-p"}>
                Either party may terminate this agreement at any time by giving written notice to the other party. In that
                event, the provisions of this agreement shall continue to apply to all services rendered prior to termination.</p>
            </li>

            <li>
              <h4 className={"entry-modal-li-h4"}> Agreement
              </h4>
              <p className={"entry-modal-li-p"}>
                If you agree with the terms of this agreement, please return a signed copy to us to indicate your
                acknowledgment and understanding of this agreement. If you fail to return a signed copy of this agreement,
                we will endeavor to follow up with you. However, regardless of whether you sign and return a copy of this
                agreement, you will be confirming your acceptance of the engagement terms outlined in this agreement if you
                provide to us the information necessary to prepare your tax return(s) or if you submit the tax
                return(s) that we have prepared for you to the tax agencies.</p>
            </li>

            <li>
              <p className={"entry-modal-li-p"}>Sincerely,</p>
            </li>

            <h5>CliftonLarsonAllen LLP</h5>
          </ul>

        </div>
      </DialogContent>
      <DialogFooter className='entryExperienceFooter fixed-footer' style={{ position: 'relative' }}>
        {(isLaptopOrDesktop && hasMultipleReturns) && <div
          style={{
            position: 'absolute',
            bottom: '25px',
            left: '45px',
            font: 'museo-sans',
            width: '36px',
            height: '16px',
            fontWeight: '700',
            fontSize: '13px',
            color: '#2E334E',
            justifySelf: 'flex-start',
            cursor: 'pointer'
          }}
          onClick={handleBackToTaxpayerSelection}
        >
          BACK
        </div>}
        {isMobile && !isMobile480 ?
          <>
            <div>
              <Button
                data-testid="acceptButtonId"
                themeType="contained"
                className="acceptContinueButton"
                onClick={handleAcceptAndContinueOnClick}
              >
                Accept and Continue
              </Button>
            </div>
            <div>
              <Button
                data-testid="declineButtonId"
                themeType="outline"
                className="declineButton"
                onClick={handleDeclineOnClick}
              >
                Decline
              </Button>
            </div>
          </> :
          <>
            <div>
              <Button
                data-testid="declineButtonId"
                themeType="outline"
                className="declineButton"
                onClick={handleDeclineOnClick}
              >
                Decline
              </Button>
            </div>
            <div>
              <Button
                data-testid="acceptButtonId"
                themeType="contained"
                className="acceptContinueButton"
                onClick={handleAcceptAndContinueOnClick}
              >
                Accept and Continue
              </Button>
            </div>
          </>
        }
      </DialogFooter>
      {(!isLaptopOrDesktop && hasMultipleReturns) &&
        <div className={'multipleEntryBackButton'} onClick={handleBackToTaxpayerSelection}>
          BACK
        </div>}
      {isShowWarningDialog ? <Dialog
        id={`warning-dialog-${nextId()}`}
        role="alertdialog"
        visible={isShowWarningDialog}
        onRequestClose={cancelWarning}
        aria-labelledby="dialog-title"
        className="WarningDialogContainer"
        data-testid="decline-warning-dialog"
      >
        <div className='WarningDialogInnerContainer declineInnerContainer' >
          <div className='WarningSignContainer'>
            <div className="SuccessDialogTitle"> {STR.ENTRY_EXPERIENCE_DECLINE_WARNING} </div>
          </div>
          <div className="WarningDialogTextContainer textContainer">
            <div className="WarningDialogText1">If you choose to decline, you will not be able to continue to the exchange and you will be logged out of the application.</div>
          </div>
          <div className="warningDialogButton">
            <Button className="warningDialogSkip" themeType="outline" data-testid="dialog-cancel" id="dialog-cancel" onClick={cancelWarning} > {STR.CANCEL} </Button>
            <Button className="warningDialogCancel" style={{ fontSize: '11px' }} data-testid="dialog-replace" id="dialog-replace" onClick={declineWarning} > {STR.DECLINE} </Button>
          </div>
        </div>
      </Dialog> : null}
      {isShowFinalWarn ? <Dialog
        id={`warning-dialog-${nextId()}`}
        role="alertdialog"
        visible={isShowFinalWarn}
        onRequestClose={() => { }}
        aria-labelledby="dialog-title"
        className={`WarningDialogContainer secondWarningContainer`}
        data-testid="decline-redirect-dialog"
      >
        <div className='WarningDialogInnerContainer' >
          <div className='WarningSignContainer'>
            <div className="SuccessDialogTitle warningTitle"> {STR.ENGAGEMENT_AGREEMENT_DECLINED} </div>
          </div>
          <div className="WarningDialogTextContainer">
            <div className="WarningDialogText1">A CLA representative will contact you to discuss <br />your tax engagement.</div>
            <div className="WarningDialogText1 warningText">You will now be logged out of the application and redirected to <a href='javascript:void(0)' className='declineLink' onClick={navigateDeclineURL}>Client HQ</a></div>
          </div>
        </div>
      </Dialog> : null}
    </>

  );
}

export default AgreementAcceptance;